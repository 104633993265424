.footer {
  width: 100%;
  padding: 20px 10px;
  background-color: var(--header-color);
  text-align: center;
  font-size: 0.8rem;
  color: white;
}
.footer > p {
  margin-top: 20px !important;
}
.footer__main {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  margin: 0px auto;
  max-width: 1000px;
  justify-content: space-between;
}
.footer__main__left {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.footer__right,
.footer__center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__main__left img {
  width: 100px;
  height: 100px;
  -webkit-user-drag: none;
  border-radius: 100px;
}
.footer__main__left > div:last-of-type {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__main__left > div > h1,
.footer__center > h1,
.footer__right > h1 {
  font-size: 0.9rem;
  user-select: none;
  font-weight: 500;
}
.footer__main__left > div > p {
  color: var(--green);
}

.footer__right > p,
.footer__center > p {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--green);
}
.footer__contacts {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  .footer__main {
    flex-direction: column;
    padding: 30px;
  }
  .footer__contacts {
    flex-direction: row;
    width: 100%;
  }
  .footer__main__left {
    flex-direction: column;
    align-self: center;
  }
  .footer__center,
  .footer__right {
    flex: 1;
  }
  .footer__main__left {
    align-items: center;
  }
  .footer__main__left > div:last-of-type {
    align-items: center;
  }
}
