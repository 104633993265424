* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  --header-color: #41444b;
  --background-color: #f5f5f5;
  --green: #43cb8f;
  background-color: var(--background-color);
}
