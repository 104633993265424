.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
}
.home::-webkit-scrollbar {
  display: none;
}
.home__main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.home__main__content {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  flex: 1;
  padding: 20px;
  background-color: white;
}
.home__main__content__left {
  flex: 0.2;
}
.home__main__content__left > img {
  width: 200px;
  user-select: none;
  -webkit-user-drag: none;
  height: 200px;
  object-fit: contain;
  border-radius: 200px;
}
.home__main__content__right {
  flex: 1;
  margin-left: 20px;
}

.home__main__content__right__section {
  margin-bottom: 30px;
}
.home__main__content__right__section > div {
  margin-bottom: 10px;
}

.home__main__content__right__section > div > h1,
.home__main__content__right__section > h1 {
  font-weight: 600;
  font-size: 0.9rem;
  user-select: none;
  text-transform: uppercase;
}
.home__main__content__right__section > div > p,
.home__main__content__right__section > p {
  color: var(--header-color);
  font-size: 0.8rem;
}
.home__main__content__right__section > p {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  text-align: justify;
  transition: all;
}
.home__main__content__right__section > p > span {
  margin-left: 10px;
}
.home__icon {
  color: var(--header-color);
  font-size: 1rem;
}
.home__main__content__right__section__row__items {
  display: flex;
  align-items: center;
  gap: 20px;
}
.home__main__content__right__section--contact {
  gap: 0px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0px;
}
.home__main__content__right__section__row__items > p {
  display: flex;
  gap: 5px;
  align-items: center !important;
}

.home__main__content__right__section__row__items > p > span {
  color: var(--green);
  font-weight: 600;
}

.home__main__content__right__section__row
  > .home__main__content__right__section {
  margin: 0;
}

.home hr {
  margin: 15px 0;
}

.home__main__content__experience__item__modal {
  background-color: white;
  width: 100%;
  padding: 20px;
  max-width: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.home__main__content__experience__item__modal > p {
  font-size: 0.8rem;
  user-select: none;
  text-align: justify;
}
.home__main__content__experience__item__modal > h1 {
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--green);
  margin-bottom: 10px;
}
.home__main__content__experience__item__modal > button {
  margin-top: 20px;
  outline: none;
  border: none;
  background-color: var(--header-color);
  color: white;
  align-self: flex-end;
  transition: all 1s;
  width: 100px;
  border-radius: 5px;
  padding: 5px;
}
.home__main__content__experience__item__modal > button:hover {
  cursor: pointer;
  background-color: var(--green);
}
.home__main__content__right__section__row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  user-select: none;
  flex: 1;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
}

.home__main__content__experience__item > small {
  cursor: pointer;
  color: var(--green);
  text-decoration: underline;
  font-weight: 500;
}
.home__main__content__right__section__row__experience {
  width: 300px;
}
.home__main__content__right__section__row__experience > p {
  font-size: 0.8rem;
  text-align: justify;
}
.home__main__content__right__section__row__experience > h1 {
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.home__main__content__right__section__row__experience {
  width: 250px;
  user-select: none;
  background-color: "red" !important;
}
.home__main__content__heading {
  margin: 10px 0;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.home__main__content__current__item {
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
}
.home__main__content__current__item__top > div {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.home__main__content__current__item__top > div > h1 {
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.home__main__content__current__item img {
  width: 200px !important;
  object-fit: contain !important;
  -webkit-user-drag: none !important;
}
.home__main__content__current__item__top > div > p {
  color: var(--green);
  font-size: 0.7rem;
  font-weight: 500;
}
.home__main__content__current__item__top > p {
  font-size: 0.8rem;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.home__current__icon {
  font-size: 1rem;
}
.home__main__content__experience__item__bottom > p {
  font-size: 0.8rem;
}
@media only screen and (max-width: 768px) {
  .home__main__content {
    flex-direction: column;
    align-items: center;
  }
  .home__main__content__left > img {
    height: 200px;
    width: 200px;
    object-fit: contain;
    display: flex;
    border-radius: 50%;
  }
  .home__main__content__right__section > div {
    justify-self: center;
    margin: 5px 10px;
  }
  .home__main__content__right__section__names {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px !important;
  }
  .home__main__content__right__section__row__experience {
    margin: 0px auto;
  }
  .home__main__content__right__section__row__experience {
    width: 100%;
  }
}
