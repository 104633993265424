.app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
}
.app__main {
  flex: 1;
}
