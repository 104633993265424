.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px 40px;
  background-color: var(--header-color);
  justify-content: space-between;
  position: sticky;
  z-index: 20;
  align-items: center;
  top: 0;
  user-select: none;
  -webkit-user-drag: none;
}
.header__right {
  color: white !important;
}
.header__right > a,
.header__left > a {
  text-decoration: none;
  margin-left: 10px !important;
}

.header__a {
  color: white !important;
  font-size: 0.9rem;
}
.header__a--active {
  color: #43cb8f !important;
  font-size: 0.9rem;
}
.header__a:hover {
  text-decoration: underline;
}

.home__main__content__experience__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}
.home__main__content__experience__item__top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.home__main__content__experience__item__top > img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.home__main__content__experience__item__top > div {
  display: flex;
  flex-direction: column;
}
.home__main__content__experience__item__top h1 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
}
.home__main__content__experience__item__top p {
  color: var(--header-color);
  font-size: 0.6rem;
}
.home__main__content__experience__item__bottom > p {
  font-size: 0.7rem;
  text-align: justify;
}
.home__main__content__experience__item > h2 {
  font-size: 0.7rem;
  font-weight: 800;
  text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .header__left {
    text-transform: uppercase !important;
    margin-bottom: 10px;
  }
  .home__main__content__experience__item {
    margin: 0px auto;
  }

  .header__a {
    font-size: 0.8rem;
  }
  .header__a--active {
    font-size: 0.8rem;
  }
}
