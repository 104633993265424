.awards {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  user-select: none;
}
.awards::-webkit-scrollbar {
  display: none;
}
.awards__main {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.awards__main__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
  flex: 1;
  background-color: white;
  padding: 30px;
}
.awards__main__content > h1 {
  margin: 30px 0;
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}
.awards__main__content > p {
  font-size: 0.8rem;
  text-align: center;
  margin-top: -25px;
}
.awards__main__content hr {
  margin: 10px 0;
}

.awards__main__content__row {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  justify-content: space-evenly;
}

.awards__main__content__award__item {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  max-width: 300px;
}

.awards__main__content__award__item h1 {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
}
.awards__main__content__award__item p {
  font-size: 0.7rem;
  color: var(--green);
}
